import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    const image = this.element;

    image.style.cursor = "zoom-in"
    this.expanded = false
  }

  toggleExpand(event) {
    const image = event.currentTarget;

    this.expanded = !this.expanded

    if (this.expanded) {
      this.expand(image)
    } else {
      this.reset(image)
    }
  }

  /** FUNCTIONS **/

  expand(image) {
    image.style.cursor = "zoom-out"
    image.style.backgroundColor = "#EEE"
    image.style.borderRadius = "12px"
    image.style.boxShadow = "black 0px 0px 10px 0px"

    // Ensure image is centered on the page
    image.style.position = "fixed"
    image.style.top = "50%"
    image.style.left = "50%"
    image.style.transform = "translate(-50%, -50%) scale(1)"
    image.style.maxWidth = "90%"
    image.style.maxHeight = "90%"
    image.style.zIndex = "1050"
  }

  reset(image) {
    image.style.cursor = "zoom-in"
    image.style.backgroundColor = ""
    image.style.borderRadius = ""
    image.style.boxShadow = ""

    // Reset image to original position and size
    image.style.position = ""
    image.style.top = ""
    image.style.left = ""
    image.style.transform = ""
    image.style.maxWidth = ""
    image.style.maxHeight = ""
    image.style.zIndex = ""
  }
}
