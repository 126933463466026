import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "list", "row", "copyFrom" ]

  initialize() {
    this.currentId = 0

    // Find the "template" row and its classes
    this.row = this.rowTarget.children[0]
    this.rowClasses = Array.from(this.row.classList).map(cls => `.${cls}`).join("")

    // Find the last instance of existing rows
    const rows = this.listTarget.querySelectorAll(this.rowClasses)
    const lastRow = rows[rows.length - 1]

    // If it exists, find the ID and add 1 to prepare for the next row
    if (lastRow) {
      const input = lastRow.querySelector("input")
      const result = input?.id.match(/_(\d+)$/);

      if (result && result[1]) {
        this.currentId = parseInt(result[1], 10) + 1
      }
    }
  }

  addRow(_, data = null) {
    // Find all select2 instances from the template row and destroy them so they won't cause issues when adding
    // I would like to do this during initialization, but it might run before select2 initialization...
    this.row.querySelectorAll("select[data-controller*='select2']").forEach((select) => {
      if ($(select).data("select2")) {
        $(select).select2("destroy")
      }
    })

    const newRow = this.row.cloneNode(true)

    // Update the ID and Name with the currentId and increment for the next
    newRow.querySelectorAll("input, select, textarea").forEach((input, _) => {
      input.name = input.name.replace(/\[-1\]/, `[${this.currentId}]`)
      input.id = input.id.replace(/-1$/, this.currentId)

      if (data != null && input.dataset.name) {
        input.value = data[input.dataset.name]
      }
    })

    this.currentId++

    newRow.classList.remove("hidden")
    this.listTarget.appendChild(newRow)
  }

  remove(e) {
    const rowToRemove = e.target.closest(this.rowClasses)
    rowToRemove.remove()
  }

  // This function will add a row for each row returned in the data.
  // In order to update the input values, please add `data-name` to each input. It should correspond to keys in the data.
  copyFrom(e) {
    const urlTemplate = e.currentTarget.dataset.url
    const id = this.copyFromTarget.value

    if (!urlTemplate) {
      console.warn("Missing URL to copy data from. `:id` will be replaced with a value passed to `copyFromTarget`.")
    }

    const url = urlTemplate.replace(":id", id)

    fetch(url, {})
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        this.clearList()

        data.forEach((row) => {
          this.addRow(null, row)
        })
      })
  }

  /* FUNCTIONS */

  clearList() {
    const rows = this.listTarget.querySelectorAll(this.rowClasses)
    rows.forEach((row) => {
      row.remove()
    })
  }
}
