import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "tab" ]
  static values = { turboFrameId: String }

  connect() {
    this.turboFrame = document.querySelector(`turbo-frame#${this.turboFrameIdValue}`)
    if (!this.turboFrame) {
      console.warn("Turbo frame not found during connect.")
    }

    // Track changes on initial page load
    this.trackChanges()

    // Track changes when new content loads
    this.turboFrame.addEventListener("turbo:frame-load", () => {
      this.trackChanges()
    })
  }

  switch(event) {
    event.preventDefault()

    if (this.hasUnsavedChanges()) {
      const confirmLeave = confirm("You have unsaved changes. Do you want to discard them?")
      if (!confirmLeave) return

      this.cleanupListeners()
    }

    // Remove active from all tabs, add it back to the one clicked.
    this.tabTargets.forEach((tab) => tab.classList.remove("active"))
    event.currentTarget.parentElement.classList.add("active")

    // Clean the content and fetch the new url
    const url = event.currentTarget.getAttribute("href")

    if (url) {
      this.turboFrame.innerHTML = `<div class="placeholder"></div>`
      this.turboFrame.src = url
    } else {
      console.warn("No url found.")
    }
  }

  resetChanges() {
    const inputs = this.turboFrame.querySelectorAll("input, textarea, select")
    inputs.forEach((input) => {
      if (input.dataset.controller?.includes("select2")) {
        $(input).val(input.dataset.originalValue).trigger("change")
      } else {
        input.value = input.dataset.originalValue
      }

      delete input.dataset.dirty
    })
  }

  /* FUNCTIONS */

  trackChanges() {
    const inputs = this.turboFrame.querySelectorAll("input, textarea, select")

    inputs.forEach((input) => {
      // Store the original value of the input in a data attribute
      input.dataset.originalValue = input.value

      if (input.dataset.controller?.includes("select2")) {
        $(input).on("change", this.handleInputChange)
      } else {
        input.addEventListener("input", this.handleInputChange)
      }
    })
  }

  cleanupListeners() {
    const inputs = this.turboFrame.querySelectorAll("input, textarea, select")

    inputs.forEach((input) => {
      input.removeEventListener("input", this.handleInputChange)
      input.removeEventListener("change", this.handleInputChange)
    })
  }

  hasUnsavedChanges() {
    const dirtyInputs = this.turboFrame.querySelectorAll("input[data-dirty], textarea[data-dirty], select[data-dirty]")
    return dirtyInputs.length > 0
  }

  handleInputChange(event) {
    const input = event.target

    // If the input value is different from the original value, mark it as dirty.
    // If the value matches the original value, remove the dirty marker.
    if (input.value !== input.dataset.originalValue) {
      input.dataset.dirty = "true"
    } else {
      delete input.dataset.dirty
    }
  }
}
