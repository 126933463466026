import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  toggle(e) {
    const element = e.currentTarget
    const targetSelector = element.dataset.target

    if (!targetSelector) {
      return console.error("Missing data-target on element")
    }

    const target = document.querySelector(targetSelector)

    if (!target) {
      return console.error(`Could not find the following target: ${targetSelector}`)
    }

    if (this.element.classList.contains("hidden")) {
      this.element.classList.remove("hidden")
      target.classList.add("hidden")
    } else {
      this.element.classList.add("hidden")
      target.classList.remove("hidden")
    }
  }
}
