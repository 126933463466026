import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = []

  loadFrame(e) {
    const targetFrameId = e.currentTarget.dataset.frameId
    const turboFrame = document.querySelector(`turbo-frame#${targetFrameId}`)

    if (turboFrame && !turboFrame.src) {
      turboFrame.src = turboFrame.dataset.src
    }
  }
}
